var site = site || {};

(function ($, Drupal) {
  Drupal.behaviors.utilityPromoV1 = {
    attach: function (context) {
      var $utilityPromo, $close;
      var $basicCarousel;

      // Starting point
      $basicCarousel = $('.site-utils__promo', context);
      $close = $('.js-close-utility-banner-formatter', $utilityPromo);
      $utilityPromo = $basicCarousel.find('.js-basic-carousel');

      if (!sessionStorage.getItem('banner_viewed')) {
        $utilityPromo.removeClass('hidden');
      } else {
        $utilityPromo.addClass('hidden');
      }
      // Close the banner for the session
      $close.once().on('click', function () {
        sessionStorage.setItem('banner_viewed', 1);
        $utilityPromo.addClass('hidden');
      });
    }
  };
})(jQuery, Drupal);
